// Webpack Imports
import * as bootstrap from 'bootstrap';

( function() {
	'use strict';

	const style = document.createElement( 'style' );
	const html = document.querySelector( 'html' );
	const body = document.querySelector( 'body' );
	const scrollbarWidth = window.innerWidth - body.clientWidth;

	let scrollpos = window.scrollY;
	const header = document.querySelector( '#header' );
	const headerHeight = header.offsetHeight;
	document.documentElement.style.setProperty( '--header-height', `${ headerHeight }px` );
	const navbarToggle = document.querySelector( '.navbar-toggler' );

	const addClassOnScroll = () => header.classList.add( 'scroll' );
	const removeClassOnScroll = () => header.classList.remove( 'scroll' );
	const addBodyClassOnScroll = () => body.classList.add( 'scroll' );
	const removeBodyClassOnScroll = () => body.classList.remove( 'scroll' );

	window.addEventListener( 'scroll', function() {
		scrollpos = window.scrollY;

		if ( scrollpos >= headerHeight ) {
			addClassOnScroll();
			addBodyClassOnScroll();
		} else {
			removeClassOnScroll();
			removeBodyClassOnScroll();
		}

		//console.log( scrollpos );
	} );

	const addOpenClassOnToggle = () => html.classList.add( 'is-open' );
	const removeOpenClassOnToggle = () => html.classList.remove( 'is-open' );
	const addActiveClassOnToggle = () => navbarToggle.classList.add( 'is-active' );
	const removeActiveClassOnToggle = () => navbarToggle.classList.remove( 'is-active' );
	navbarToggle.onclick = () => {
		if ( navbarToggle.classList.contains( 'is-active' ) ) {
			removeActiveClassOnToggle();
		} else {
			addActiveClassOnToggle();
		}
		if ( html.classList.contains( 'is-open' ) ) {
			removeOpenClassOnToggle();
		} else {
			addOpenClassOnToggle();
		}
	};

	const menuToggle = document.getElementById( 'navbar' );
	const navLinks = menuToggle.querySelectorAll( '.nav-link' );
	const bsCollapse = new bootstrap.Collapse( menuToggle, { toggle: false } );
	navLinks.forEach( ( l ) => {
		l.addEventListener( 'click', () => {
			if ( window.innerWidth < 992 ) {
				if ( navbarToggle.classList.contains( 'is-active' ) ) {
					removeActiveClassOnToggle();
				} else {
					addActiveClassOnToggle();
				}
				if ( html.classList.contains( 'is-open' ) ) {
					removeOpenClassOnToggle();
				} else {
					addOpenClassOnToggle();
				}
				bsCollapse.toggle();
			}
		} );
	} );

	// Focus input if Searchform is empty
	[].forEach.call( document.querySelectorAll( '.search-form' ), ( el ) => {
		el.addEventListener( 'submit', function( e ) {
			const search = el.querySelector( 'input' );
			if ( search.value.length < 1 ) {
				e.preventDefault();
				search.focus();
			}
		} );
	} );

	// Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
	const popoverTriggerList = [].slice.call( document.querySelectorAll( '[data-bs-toggle="popover"]' ) );
	const popoverList = popoverTriggerList.map( function( popoverTriggerEl ) {
		return new bootstrap.Popover( popoverTriggerEl, {
			trigger: 'focus',
		} );
	} );
}() );
